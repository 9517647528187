<template>
    <div style="width: 100%">
        <!-- 头部  -->
        <div style="width: 100%;height: 60px;background: #FFFFFF;overflow: hidden">
            <typeHead></typeHead>
        </div>
        <div style="display: flex;justify-content: center">
            <div>
                <!--  服务分类 -->
                <div style="width: 1000px;height: 156px;background: #FFFFFF;margin-top: 10px;">
                    <div style="height: 24px;font-size: 20px;color:red;margin: 0 0 0 30px;padding-top: 20px;font-weight: bold;">*<span style="color: #444444">服务分类</span></div>
                    <div style="width: 430px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px;float: left">
                        <el-cascader  style="width: 430px;"
                                      ref="cascaderArr"
                                      class="cascader"
                                      v-model="type"
                                      :options="type_list"
                                      @change="handleChange()"
                                      placeholder="请选择服务分类">
                        </el-cascader>
                    </div>
                    <div style="float: left;margin: 20px 0 0 10px;">没有更多分类？<span style="color: #2970FF;cursor: pointer" @click="addsort">前往设置</span></div>
                    <el-dialog :close-on-click-modal="false"
                        :visible.sync="dialogVisible"
                        width="30%">
                        <div style="width: 100%;display: flex;">
                            <div style="width: 30%">
                                <el-input type="text" placeholder="请输入一级" v-model="first_order" maxlength="10" show-word-limit></el-input>
                            </div>
                            <div style="width: 30%;margin-left: 3%">
                                <el-input type="text" placeholder="请输入二级" v-model="Second_level" maxlength="10" show-word-limit></el-input>
                            </div>
                            <div style="width: 30%;margin-left: 3%">
                                <el-input type="text" placeholder="请输入三级" v-model="three_level" maxlength="10" show-word-limit></el-input>
                            </div>
                        </div>
                        <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                      </span>
                    </el-dialog>
                </div>
                    <!--  左部分-->
                <div style="width: 100%">
                       <div style="display: flex;justify-content: center">
                           <div style="height: auto;">
                               <!--  标题内容部分 -->
                               <div style="width: 1000px;height: auto;background: #FFFFFF;margin-top: 10px;">
                                   <div style="height: 21px;font-size: 20px;color: red;padding: 10px 0 0 30px;font-weight: bold;">*<span style="color: #444444">基础信息</span></div>
                                   <!--  标题 -->
                                   <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;">服务标题</div>
                                   <div style="width: 940px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px;">
                                       <el-input type="text" :rows="2" placeholder="请输入标题" v-model="title" maxlength="30" show-word-limit />
                                   </div>
                                   <!-- 内容   -->
                                   <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;">服务介绍</div>
                                   <div style="width: 940px;height: auto;background: #FFFFFF;margin-top:12px;margin-left: 30px;">
                                       <Editor :tinymce_height="'780'"  :innerDrawer = "innerDrawer = true" :value="content"  ref="ch"></Editor>
                                   </div>
                                   <div style="height: 30px"></div>
                                   <div style="margin-left:30px;">
                                       <el-radio-group v-model="optionprice">
                                           <el-radio :label="1">固定价格</el-radio>
                                           <el-radio :label="2">区间价格</el-radio>
                                       </el-radio-group>
                                   </div>
                                   <!-- 固定价格   -->
                                   <div v-if="optionprice === 1">
                                       <div  style="width: 400px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px;">
                                           <el-input placeholder="0.00" prefix-icon="el-icon-coin" type="number" v-model="before"></el-input>
                                       </div>
                                   </div>
                                   <!-- 区间价格   -->
                                   <div v-if="optionprice === 2" style="display: flex">
                                       <div style="width: 400px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px;">
                                           <el-input placeholder="0.00" prefix-icon="el-icon-coin" type="number" v-model="before"></el-input>
                                       </div>
                                       <div style="margin: 20px 0 0 24px">-</div>
                                       <div style="width: 400px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px;">
                                           <el-input placeholder="0.00" prefix-icon="el-icon-coin" type="number" v-model="beforebig"></el-input>
                                       </div>
                                   </div>
	                               <div style="font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;">服务阶段
		                               <el-button type="danger" size="mini" @click="addFlowPath()">添加</el-button>
	                               </div>
	                               <div class="options-container" style="width: 940px;height: auto;background: #FFFFFF;margin-top:12px;margin-left: 30px;">
		                               <div style="width: 100%;height: auto;">
			                               <div class="nested-container">
				                               <div class="form-container" >
					                               <el-form ref="form" v-for="(item,index) in flowPath" :key="index" :model="item" label-width="80px">
						                               <el-form-item :label="'第'+(index+1)+'阶段'" label-width="80px">
							                               <el-input v-model="item.value" type="text" placeholder="请输入阶段内容">
								                               <template slot="append">
									                               <el-button @click="removeFlowPath(index)" type="danger" size="mini">删除</el-button>
								                               </template>
							                               </el-input>
						                               </el-form-item>
					                               </el-form>
				                               </div>
			                               </div>
		                               </div>
	                               </div>
                                   <!-- 添加标签     -->
                                   <div style="height: 24px;font-size: 20px;color: #444444;margin: 10px 0 0 30px;">服务标签（最多10个标签）</div>
                                   <div style="display: flex">
                                       <div style="width: 770px;background: #FFFFFF;height: 56px;margin: 12px 0 0 30px;">
                                           <el-input type="text" placeholder="请输入标签" v-model="newTag" maxlength="20" show-word-limit></el-input>
                                       </div>
                                       <div style="margin: 10px 0 0 20px;" @click="addTag"><el-button type="primary">添加标签</el-button></div>
                                   </div>
                                   <el-tag v-for="(item, index) in tags" :key="index" closable @close="deleteTag(index)" style="float: left;margin-left: 30px;margin-top: 5px">
                                       {{ item }}
                                   </el-tag>
                                   <!--  服务输入条件   -->
                                   <div>
                                       <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;clear:both">服务输入条件</div>
                                       <div style="width: 770px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px;float: left">
                                           <el-input v-model="condition" placeholder="添加其他输入条件" show-word-limit maxlength="50"></el-input>
                                       </div>
                                       <div style="float: left;margin-top: 10px;margin-left: 20px">
                                           <el-button type="primary" @click="chanadd">添加条件</el-button>
                                       </div>
                                       <div style="clear: both">
                                           <el-tag v-for="(it,index) in condShop" :key="index" type="success" closable @close="trashCan(index)" style="float: left;margin-left: 30px;">
                                               {{ it }}
                                           </el-tag>
                                       </div>
                                   </div>
                                   <!--  服务输出形式 -->
                                   <div>
                                       <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;clear:both">服务输出形式</div>
                                       <div style="width: 770px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px;float: left;">
                                           <el-input v-model="form" placeholder="添加其他输入条件" show-word-limit maxlength="50"></el-input>
                                       </div>
                                       <div style="float: left;margin-top: 10px;margin-left: 20px">
                                           <el-button type="primary" @click="chancol">添加形式</el-button>
                                       </div>
                                       <div style="clear: both">
                                           <el-tag v-for="(it,index) in formnow" :key="index" type="success" closable @close="trashCan2(a)" style="float: left;margin-left: 30px;">
                                               {{ it }}
                                           </el-tag>
                                       </div>
                                   </div>
                                   <!--  服务达到的标准 -->
                                   <div>
                                       <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;clear:both">服务达到的标准</div>
                                       <div style="width: 770px;height: 56px;background: #FFFFFF;margin: 12px 0 0 30px;float: left;">
                                           <el-input v-model="number" placeholder="添加其他输入条件" show-word-limit maxlength="50"></el-input>
                                       </div>
                                       <div style="float: left;margin-top: 10px;margin-left: 20px">
                                           <el-button type="primary" @click="chantype">添加标准</el-button>
                                       </div>
                                       <div style="clear: both">
                                           <el-tag v-for="(it,index) in numberType" :key="index" type="success" closable @close="trashCan3(a)" style="float: left;margin-left: 30px;">
                                               {{ it }}
                                           </el-tag>
                                       </div>
                                   </div>
                                   <!--    规格预览-->
                                   <div class="containerdiv" style="padding-top: 20px">
                                       <div class="nested-container">
                                           <div class="form-container" >
                                               <el-form ref="form" v-for="(item,index) in tionsList.data" :key="index" :model="item" label-width="80px">
                                                   <el-form-item :label="item.key" label-width="80px" v-if="item.userType===1">
                                                       <el-input v-if="item.type===1" v-model="item.value" type="text" placeholder="文本型输入框"></el-input>
                                                       <el-input v-if="item.type===2" v-model="item.value" type="number" placeholder="数字型输入框"></el-input>
                                                       <label v-if="item.type===3 || item.type===5">
                                                           <el-radio border size="mini" v-for="(v,i) in item.value" :key="i" v-model="item.radio" :label="v.radio">{{ v.name }}</el-radio>
                                                       </label>
                                                       <el-checkbox-group v-if="item.type===4" v-model="item.selectList" size="mini">
                                                           <el-checkbox-button v-for="(v,i) in item.value" :key="i" :label="v.radio">{{ v.name }}</el-checkbox-button>
                                                       </el-checkbox-group>
                                                   </el-form-item>
                                               </el-form>
                                           </div>
                                       </div>
                                   </div>
                                   <div style="height: 100px;background: #FFFFFF"></div>
                               </div>
                               <div style="width: 1000px;height: 72px;background: #FFFFFF;margin-top: 10px;">
                                   <div style="font-size: 20px;color: #E82B00;float: left;margin: 24px 0 0 30px;font-weight: bold;">*<span style="color: #444444">上架时间</span></div>
                                   <div style="float: left;padding:26px 0 0 50px; ">
                                       <el-radio-group v-model="listing">
                                           <el-radio :label="0">放入仓库</el-radio>
                                           <el-radio :label="1">立即上架</el-radio>
                                       </el-radio-group>
                                   </div>
                               </div>
                               <!--服务图片部分 -->
                               <div style="width: 1000px;height: auto;background: #FFFFFF;margin-top: 10px;">
                                   <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;">服务图片</div>
                                   <el-upload accept="image/*" style="margin-left: 30px;margin-top: 12px;float: left"  class="upload-demo" action="" drag multiple :http-request="upload_img" :limit=20 :show-file-list="false" :on-exceed="filelist">
                                       <img src="../../assets/imgs/index/add1.png" style="padding-top: 20px;object-fit: cover">
                                       <div class="el-upload__text" ><em>点击上传图片</em></div>
                                   </el-upload>
                                   <div style="position: relative;float: left;border-radius: 6px;" v-for="(item,index) in imgs" :key="index">
                                       <div @click="removeImg(index)" style="position: absolute;left: 160px;top: 10px;z-index: 1;">
                                           <i class="el-icon-delete" style="font-size:18px;font-weight:bold;color: #FFFFFF"></i>
                                       </div>
                                       <img :src="item.url" style="width: 150px; height: 172px;float: left;padding: 10px;margin-left: 20px;object-fit: cover;border-radius: 5px;">
                                   </div>
                                   <!--服务视频部分 -->
                                   <div style="height: 24px;font-size: 20px;color: #444444;margin: 0 0 0 30px;padding-top: 20px;clear:both"></div>
                               </div>
                           </div>
                       </div>
                    </div>
            </div>
            <!--   右边列表查询-->
            <div style="width: 490px;height: 156px;background: #FFFFFF;margin: 10px;">
                 <specification :specificationsGroup="specificationsGroup" :Specifications="Specifications" ref="childDatas"></specification>
            </div>
        </div>
        <!--  底部 -->
        <div style="float: left;width: 100%;overflow: hidden">
            <div style="width: 100%;height: auto;background: #FFFFFF;float: left;display: flex;justify-content: center;align-content: center;padding-top: 28px;margin-top: 5px;">
                <div style="cursor: pointer">
                    <el-button type="info">保存草稿</el-button>
                </div>
                <div v-if="typeof id != 'undefined' " @click="addService"  style="margin-left: 40px;cursor: pointer">
                    <el-button type="primary">保存</el-button>
                </div>
                <div v-else @click="addService" style="margin-left: 40px;cursor: pointer">
                    <el-button type="primary">提交服务信息</el-button>
                </div>
                <div style="height: 50px;"></div>
            </div>
        </div>
    </div>
</template>

<script>
import typeHead from "./components/typeHead";
// 服务规格部分
import specification from "./components/specification"
import Editor from "@/components/common/Editor"

//  商品属性部分
export default {
    name:'addService',
    components: {
        typeHead,
        specification,
        Editor

    },
    props: {

    },
    computed: {
        // isEditing() {
        //     return this.id !== 'undefined';
        // }
    },
    created() {

    },
    data () {
        return {
	        flowPath:[{
		        value:''
	        }],
            id:this.$route.query.id,
            title:"",
            content:"",
            imgs:[],
            cation:"",
            removeImgs:[],
            spaceSize:0,
            before:"",
            after:"",
            newTag: '', // 存储新标签的名称
            tags: [],    // 存储所有标签的数组
            condition:'',
            cond:[],
            form:"",
            formnow:[],
            number:"",
            numberType:[],
            condShop:[],
            type_list:[],
            tionsList:{},
            did:"",
            specificationsGroup:[],
            optionprice:1,
            listing:0,
            beforebig:"",
            type:"",
            v:[],
            Specifications:[],
            /**
             * 添加分类
             */
            dialogVisible: false,
            first_order:'',
            Second_level:'',
            three_level:'',
        }
    },
    mounted:function(){
          this.getTypeList();
          this.getMyShop();
          if ( typeof this.$route.query.id != 'undefined' ){
              this.getShopServiceInfo()
          }


    },
    methods:{
	    addFlowPath(){
		    this.flowPath.push({
			    value:''
		    })
	    },
	    removeFlowPath(index){
		    this.flowPath.splice(index,1)
	    },
        // 弹出事件
        addsort(){
            this.dialogVisible = true;
        },
        // 发布服务
        addService(){
            var params = {};
            if (typeof this.$route.query.id != 'undefined'){
                //    店铺id
                params.id = this.$route.query.id;
            }
            var that = this;
            params.shopId = that.did.id;
            // 0 放仓库 1上架
            params.status = that.listing;
            // 关联服务
            params.link = "[]";
            // 商品标题
            if(that.title == ""){
                that.utils.err("服务标题不能为空");return false
            }
            params.title = that.title;
            //  服务内容
            let chil = that.$refs.ch;
            let content = chil.get_content();
            content = content.replace(/amp;/g,"");
            params.content = content;
            if(params.content == ""){
                that.utils.err("请填写内容");
                return false;
            }
            //  价格
            if(that.before == ""){
                that.utils.err("价格不能为空");return false
            }
            params.minPrice = that.before;
            if (that.optionprice == 2){
                if (that.beforebig == ""){
                    that.utils.err("区间价格不能为空");return false
                }
            }
            params.maxPrice = that.beforebig;
            if(that.optionprice == 1){
                params.maxPrice = 0;
            }
            //    分类
            if(that.type == ""){
                that.utils.err("分类不能为空");return false
            }
            params.type = JSON.stringify(that.type);

            //  图片
            if(that.imgs.length == 0){
                that.utils.err("请上传图片");return false
            }
            params.images = JSON.stringify(that.imgs);

            // 商家承诺
            params.commitment = JSON.stringify(that.$refs.childDatas.nol);
            //  标签
            if(that.tags == ""){
                that.utils.err("服务标签至少为一种");return false
            }
            params.label = JSON.stringify(that.tags);

            // //    输入要求
            if(that.condShop == ""){
                that.utils.err("输入条件至少为一种");return false
            }
            params.input = JSON.stringify(that.condShop);
            //    输出形式
            if(that.formnow == ""){
                that.utils.err("输出形式至少为一种");return false
            }
            params.output = JSON.stringify(that.formnow);
            //    达到的标准
            if(that.numberType == ""){
                that.utils.err("达到的标准至少为一种");return false
            }
            params.standard = JSON.stringify(that.numberType);
            //属性模板
            if (this.$route.query.id != 'undefined'){
                params.otherSpecifications =  JSON.stringify( that.$refs.childDatas.SpecificationsData);
            }else if (that.$refs.childDatas.typeshop === ""){
                params.otherSpecifications = "[]"
            }else {
                params.otherSpecifications =  JSON.stringify(that.$refs.childDatas.specificationsGroup[ that.$refs.childDatas.typeshop].data);
            }
	        params.flowPath =  JSON.stringify(that.flowPath)
            console.log(params)
            that.newApi.addShopService(params).then((res)=>{
                if(res.isSuccess == 1){
                    that.utils.sus(res.data)
                    that.$router.push("/shop/shopCode/")
                }

            }).catch((err)=>{
                console.log(err)
            })
        },
        // 商务信息详情
        getShopServiceInfo:function(){
            var that = this;
            that.newApi.getShopServiceInfo({
                serviceId:that.$route.query.id,
            }).then((res)=>{
                that.title = res.data.title;
                that.type = res.data.type;
                that.content = res.data.content;
                that.before = res.data.minPrice;
                that.beforebig = res.data.maxPrice;
                that.imgs = res.data.images;
                that.tags = res.data.label;
                that.condShop = res.data.input;
                that.formnow = res.data.output;
                that.numberType = res.data.standard;
                that.listing = res.data.status;
                that.$refs.childDatas.nol = res.data.commitment;
                that.Specifications = res.data.otherSpecifications;
	            that.flowPath = res.data.flowPath;

            }).catch((err)=>{
                console.log(err)
            })
        },
        get_content:function () {
            var content = window.tinymce.activeEditor.getContent();
            return content;
        },
        // 父组件接收 子组件传过来的值
        colseRelese(it){
            console.log(it)
            var that = this;
            that.tionsList = it;

        },
        // colseRelesecode(type) {
        //     this.checkedNode = type;
        //     this.now = this.checkedNode;
        //
        //     if (this.checkedNode === this.now) {
        //         this.tionsList.data = [];
        //     }
        // },
        getTypeList(){
            var that = this;
            that.newApi.getTypeList({
                lv: 3,
            }).then((res)=>{
                that.type_list = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 查询我的店铺
        getMyShop(){
            var that= this;
            that.newApi.getMyShop({}).then((res)=>{
                that.did = res.data
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 添加标签事件
        addTag() {
            var that = this;
            if (that.newTag == ""){
                that.$message.error('标签内容不能为空');return false
            }
            if(that.tags.length >= 10){
                that.$message.error('最多添加10个标签');return false
            }
            that.tags.push(that.newTag);
            that.newTag = '';
        },
        deleteTag(index){
            var that = this;
            that.tags.splice(index, 1);
        },
        // 添加服务输入条件事件
        chanadd(){
            var that = this;
            if (that.condition == ""){
                that.$message.error('服务条件不能为空');return false
            }
            that.condShop.push(that.condition);
            that.condition = '';
        },
        trashCan(index){
            var that = this;
            that.condShop.splice(index, 1);
        },
        // 添加服务输出形式事件
        chancol(){
            var that = this;
            if (that.form == ""){
                that.$message.error('服务输出形式不能为空');return false
            }
            that.formnow.push(that.form);
            that.form = '';
        },
        trashCan2(index){
            var that = this;
            that.formnow.splice(index, 1);
        },
        // 添加服务达到的标准事件
        chantype(){
            var that = this;
            if (that.number == ""){
                that.$message.error('标准不能为空');return false
            }
            that.numberType.push(that.number);
            that.number = '';
        },
        trashCan3(index){
            var that = this;
            that.numberType.splice(index, 1);
        },

        upload_img:function(params){
            this.img_length++ ;
            if (this.img_length>20) {
                this.$message.error('图片数量达到最大数量！');
                return false;
            }
            var that = this;
            var file = params.file;

            const isLt5M = file.size / 1024 / 1024 < 10;
            if (!isLt5M) {

                //this.$message.error('上传文件大小不能超过 20MB!')
                if (file.type.indexOf('image') > -1) {
                    let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = function(e) {

                        let image = new Image()     //新建一个img标签（不嵌入DOM节点，仅做canvas操作)
                        image.src = e.target.result    //让该标签加载base64格式的原图
                        image.onload = function() {    //图片加载完毕后再通过canvas压缩图片，否则图片还没加载完就压缩，结果图片是全黑的
                            let canvas = document.createElement('canvas'), //创建一个canvas元素
                                context = canvas.getContext('2d'),    //context相当于画笔，里面有各种可以进行绘图的API
                                imageWidth = image.width / 2,    //压缩后图片的宽度，这里设置为缩小一半
                                imageHeight = image.height / 2,    //压缩后图片的高度，这里设置为缩小一半
                                data = ''    //存储压缩后的图片
                            canvas.width = imageWidth    //设置绘图的宽度
                            canvas.height = imageHeight    //设置绘图的高度

                            //使用drawImage重新设置img标签中的图片大小，实现压缩。drawImage方法的参数可以自行查阅W3C
                            context.drawImage(image, 0, 0, imageWidth, imageHeight)

                            //使用toDataURL将canvas上的图片转换为base64格式
                            data = canvas.toDataURL('image/jpeg')

                            //调用

                            var files = that.dataURLtoFile(data,file.name);
                            // var size = files.size / 1000 /1000;
                            that.utils.upload(files,function(url){
	                            if (!url){
		                            return false;
	                            }
                                that.imgs.push(url);
                            })
                        }
                    }

                }else{
                    that.$message.error('只能上传图片！')
                    return false
                }
            }else{
                that.utils.upload(file,function(url){
	                if (!url){
		                return false;
	                }
                    that.imgs.push(url);
                })
            }
        },
        filelist:function (files) {
            if (files.length>this.limit) {
                this.$message.error('图片数量不能超过20张!');
            }
        },
        // 删除图片
        removeImg:function(index){
            // var that = this;
            // this.utils.confirm("移除这个图片？",function(){
            this.removeImgs.push(this.imgs[index]);
            console.log(this.spaceSize,this.imgs[index].size );
            this.spaceSize = this.spaceSize - this.imgs[index].size;
            console.log(this.spaceSize );
            this.imgs.splice(index,1);
            // })
        },
        //   选择分类调用查询分类下的规格
        handleChange() {
            // const checkedNode = this.$refs["cascaderArr"].getCheckedNodes();
            //
            // for (let i = 0; i < checkedNode.length; i++) {
            //     const type = {
            //         type1: checkedNode[i].path[0],
            //         type2: checkedNode[i].path[1],
            //         type3: checkedNode[i].path[2]
            //     };
            //     this.$parent.colseRelesecode(type);
            // }
            //
            // this.typeName = checkedNode[0].data.label;

            this.newApi.getTypeSpecificationsGroup({ "type": JSON.stringify(this.type),menu:'serves' }).then((res) => {
                this.specificationsGroup = res.data;
            });
        },
    }
}
</script>

<style scoped>
/deep/ .el-upload-dragger {
    width: 172px;
    height: 172px;
}
/deep/ .el-radio__inner {
    width: 16px;
    height: 16px;
}
/deep/ .el-radio__inner::after {
    width: 7px;
    height: 7px;
}
/deep/  .el-radio__label {
    font-size: 16px;
}

</style>
